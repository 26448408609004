@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-room {
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 85vh;
    margin-top: 3rem;
    .btn-create-new-room-container {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary;
            &:nth-child(2) {
              margin-left: 1rem;
            }
        }
    }
    .student-rom-search-content {
      @include displayFlex(row, space-between, center);
      width: 90%;
      .student-qtd {
        @include displayFlex(row, center, center);
        background-color: $green;
        padding: 0.5rem;
        border-radius: 5px;
        .student-qtd-icon {
          font-size: 20px;
          color: #fff;
          margin-left: 1rem;
        }
        span {
          color: #fff;
          font-size: 2rem;
          font-weight: 500;
        }
      }
    }
    .filter-content-action {
        @include displayFlex(row, flex-start, center);
        width: 93%;
        margin-bottom: 3rem;
        h1 {
            font-weight: 500;
            font-size: 1.6rem;
            color: $green;
        }
        .filter {
            @include displayFlex(row, flex-start, center);
            list-style: none;
            gap: 1rem;
        }
    }
    .header-table-element-action {
        position: relative;
        width: 90%;
        margin-top: 1rem;
        border-top: 2px solid $green;
        border-radius: 5px;
        box-shadow: 1px 1px 3px $gray-for;
        z-index: 1;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.3fr 1.5fr 1.5fr 0.7fr 0.3fr 0.7fr 0.7fr 0.3fr 0.5fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem 1rem;
            }
        }
    }
    .main-table-action {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        height: 100%;
        padding-bottom: 1rem;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}

@media (max-width: 550px) {
  .table-container-room {
    .student-rom-search-content {
      width: 98%;
    }
    .btn-create-new-room-container {
      width: 98%;
      margin-top: 1rem;
    }
    .search-element {
      width: 98%;
    }
    .header-table-element-action {
      width: 98%;
      .header-table-ul {
        grid-template-columns: 0.3fr 1.5fr 0.7fr 0.3fr 1fr 0.4fr;
        .header-table-ul-li {
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(7) {
            display: none;
          }
        }
      }
    }
  }
}