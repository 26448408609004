@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-add-container-room {
    position: absolute;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100vh;
    &.display-false {
        display: none;
    }
    .form-add-element-room {
        @include displayFlex(column, center, center);
        overflow: hidden;
        background-color: white;
        width: 500px;
        border-radius: 3px;
        box-shadow: 1px 1px 4px $gray-tree;
        .input-content {
          width: 90%;
        }
        .header-form-add-element-content {
            @include displayFlex(row, space-between, center);
            width: 100%;
            padding: 1rem 3rem;
            margin-bottom: 2rem;
            color: $font-color-primary;
            background-color: #13975a;
            h1 {
                font-size: 1.7rem;
                padding: 0rem 1rem;
                color: $font-color-primary;
            }
            svg {
                margin-right: 1rem;
                cursor: pointer;
            }
        }
        .body-form-add-element-content {
            padding: 3rem;
            padding-bottom: 0px;
            width: 90%;
            .buttons-content {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin: 3rem 0rem;
                button {
                    cursor: pointer;
                    margin-right: 1rem;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    &.save-button {
                        background-color: $green;
                        color: $font-color-primary;
                    }
                    &.cancel-button {
                        border: 1px solid $green;
                    }
                }
            }
        }
    }
    z-index: 2;
}

@media (max-width: 600px) {
  .form-add-container-room {
    .form-add-element-room {
      width: 100%;
    }
  }
}