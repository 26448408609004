@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-add-container-frequency {
    position: absolute;
    top: -10px;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100vh;
    z-index: 10;
    &.display-false {
        display: none;
    }
    .form-add-element-activity {
        overflow: hidden;
        position: relative;
        background-color: white;
        width: 600px;
        height: 80%;
        max-height: 600px;
        border-radius: 3px;
        box-shadow: 1px 1px 4px $gray-tree;
        z-index: 9999;
        .header-form-add-element-content {
            @include displayFlex(row, space-between, center);
            padding: 1rem 3rem;
            margin-bottom: 2rem;
            color: $font-color-primary;
            background-color: #13975a;

            h1 {
                font-size: 1.7rem;
                padding: 0px;
                color: $font-color-primary;
            }
            svg {
                cursor: pointer;
            }
        }
        .body-form-add-element-content {
            overflow-y: auto;
            height: 80%;
            padding: 3rem;
            padding-bottom: 0px;
            &::-webkit-scrollbar {
                width: 12px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $green;
                border-radius: 20px;
            }
            .student-frequency-content {
                width: 100%;
                .student-frequency-content-header {
                    width: 100%;
                    margin-bottom: 2rem;
                    border-top: 2px solid $green;
                    border-radius: 5px;
                    box-shadow: 1px 1px 3px $gray-for;
                    .student-frequency-content-header-list {
                        display: grid;
                        grid-template-columns: 0.2fr 1.5fr 1fr;
                        list-style: none;
                        h2 {
                            padding: 1rem;
                        }
                    }
                }
            }
            
            .error-content {
                font-size: 1.3rem;
                color: red;
            }
            .input-date-content {
                @include displayFlex(column, center, start);
                margin: 3rem 1rem;
                label {
                  font-size: 2rem;
                  margin-bottom: 2rem;
                }
                input {
                    border: none;
                    outline: none;
                    border-bottom: 1px solid $green;
                    font-size: 1.7rem;
                }
            }
            .buttons-content {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin: 3rem 0rem;
                button {
                    cursor: pointer;
                    margin-right: 1rem;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    &.save-button {
                        background-color: $green;
                        color: $font-color-primary;
                    }
                    &.cancel-button {
                        border: 1px solid $green;
                    }
                }
            }
        }
    }
    z-index: 2;
}

@media (max-width: 600px) {
    .form-add-container-frequency {
        .form-add-element-activity {
            width: 100%;
        }
        .body-form-add-element-content {
            width: 90%;
            padding: 0px;
        }
    }
}
