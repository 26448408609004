@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.student-missed-information-content-body-list {
    display: grid;
    grid-template-columns: 0.2fr 2fr 0.5fr 0.5fr 0.5fr;
    width: 100%;
    list-style: none;
    border-radius: 5px;
    border-bottom: 1px solid  $gray-for;
    border-collapse: collapse;
    &:hover {
        background-color: rgba(128, 128, 128, 0.075);
    }
    li {
        font-size: 1.5rem;
        font-weight: 500;
        color: $gray;
        padding: 1rem;
        &:nth-child(3) {
            text-align: center;
        }
        &:nth-child(4) {
            text-align: center;
        }
        &:nth-child(5) {
            text-align: center;
        }
        &::before {
            position: relative;
            content: '';
            left: -10px;
            width: 1px;
            height: 50px;
            background-color: $gray-for;
            z-index: 99999;
        }
    }
}